<template lang="pug">
  span
    | {{ title }}
    i.required(v-if="required" :class="{ 'required-label': requiredLabel !== '*' }") {{ requiredLabel }}
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true
      },
      required: {
        type: Boolean,
        default: false
      },
      requiredLabel: {
        type: String,
        default: "*"
      }
    }
  }
</script>

<style lang="sass" scoped>
  .required-label
    margin-left: 8px
</style>
